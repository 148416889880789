import Aos from 'aos';
import 'aos/dist/aos.css';
import window from 'global';
import * as React from 'react';
import Footer from '../components/Footer';
import Head from '../components/Head';
import MainSubtitle from '../components/MainSubtitle';
import MainTitle from '../components/MainTitle';
import MobileNavbar from '../components/MobileNav';
import Navbar from '../components/NavBar';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import curriculumAlgBgImg from '../images/algorithm-and-data-structure.webp';
import curriculumBgImg from '../images/curriculum-learn-coding.webp';
import curriculumDataBgImg from '../images/data-analytics.webp';
import curriculumGameBgImg from '../images/game-development.webp';
import curriculumMobileBgImg from '../images/mobile-development.webp';
import curriculumRobotBgImg from '../images/robot.webp';
import curriculumWebBgImg from '../images/website-development.webp';

const CurriculumPage = () => {
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    Aos.init();
    Aos.refresh();
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <Head pageTitle="Curriculum" />
      {dimension.width > breakpoint ? <Navbar /> : <MobileNavbar />}
      <Section backgroundImage={curriculumBgImg} customStyle={{ textAlign: 'center' }}>
        <SectionContentContainer customStyle={{ left: 0 }}>
          {dimension.width > breakpoint ? (
            <>
              {' '}
              <MainTitle text="CURRICULUM" style={{ fontSize: 'calc(3vw + 3vh)' }} />
              <br />
              <MainSubtitle text="EQUIP STUDENTS WITH FUTURE-READY TECHNICAL SKILLS" style={{ fontSize: 'calc(1.2vw + 1.2vh)' }} />
            </>
          ) : (
            <>
              {' '}
              <MainTitle text="CURRICULUM" style={{ fontSize: 'calc(4vw + 4vh)' }} />
              <br />
              <MainSubtitle text="EQUIP STUDENTS WITH FUTURE-READY TECHNICAL SKILLS" style={{ fontSize: 'calc(2vw + 2vh)' }} />
            </>
          )}
        </SectionContentContainer>
      </Section>
      <Section backgroundImage={curriculumWebBgImg}>
        {dimension.width > breakpoint ? (
          <>
            <SectionContentContainer className="col-4">
              <div data-aos="fade-down" data-aos-duration="500">
                <MainTitle text="WEB DEVELOPMENT" />
              </div>
              <br />
              <div data-aos="fade-down" data-aos-duration="500">
                <MainSubtitle
                  text="Learn to create your very first webpage from scratch. Understand how webpage's components works together, how integrate frontend with backend, and create your own interactive dynamic webpage"
                />
              </div>
            </SectionContentContainer>
          </>
        ) : (
          <>
            <SectionContentContainer>
              <div data-aos="fade-down" data-aos-duration="500">
                <MainTitle text="WEB DEVELOPMENT" />
              </div>
              <br />
              <div data-aos="fade-down" data-aos-duration="500">
                <MainSubtitle
                  text="Learn to create your very first webpage from scratch. Understand how webpage's components works together, how integrate frontend with backend, and create your own interactive dynamic webpage"
                />
              </div>
            </SectionContentContainer>
          </>
        )}
      </Section>
      <Section backgroundImage={curriculumMobileBgImg}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer className="col-4">
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="MOBILE APP DEVELOPMENT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn the basics of mobile app development. Get to create your very first mobile app on both Android and IOS using Dart"
              />
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="MOBILE APP DEVELOPMENT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn the basics of mobile app development. Get to create your very first mobile app on both Android and IOS using Dart"
              />
            </div>
          </SectionContentContainer>
        )}
      </Section>
      <Section backgroundImage={curriculumGameBgImg}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer className="col-4">
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="GAME DEVELOPMENT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about game development basics, and work on hands on project of creating a game plugins for your own using Java"
              />
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="GAME DEVELOPMENT" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about game development basics, and work on hands on project of creating a game plugins for your own using Java"
              />
            </div>
          </SectionContentContainer>
        )}
      </Section>
      <Section backgroundImage={curriculumRobotBgImg}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer className="col-4" customStyle={{ left: '55%' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ROBOTICS" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Develop your very own program to control a robot using Python and CodeZ. Get hands-on robotic programing experiences by working on projects created by experts"
              />
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ROBOTICS" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Develop your very own program to control a robot using Python and CodeZ. Get hands-on robotic programing experiences by working on projects created by experts"
              />
            </div>
          </SectionContentContainer>
        )}
      </Section>
      <Section backgroundImage={curriculumDataBgImg}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer className="col-4">
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="DATA ANALYTICS" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about data analytics basics. Expose youself to commonly used data analytics tools and get hands-on experiences via DIY projects"
              />
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="DATA ANALYTICS" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about data analytics basics. Expose youself to commonly used data analytics tools and get hands-on experiences via DIY projects"
              />
            </div>
          </SectionContentContainer>
        )}
      </Section>
      <Section backgroundImage={curriculumAlgBgImg}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer className="col-4" customStyle={{ left: '55%' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ALGORITHM & DATA STRUCTURE" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about commonly used data structures and algorithms in programming. Master the algorithms by working on real-life problems proposed by our top-notch tutors"
              />
            </div>
          </SectionContentContainer>
        ) : (
          <SectionContentContainer>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="ALGORITHM & DATA STRUCTURE" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle
                text="Learn about commonly used data structures and algorithms in programming. Master the algorithms by working on real-life problems proposed by our top-notch tutors"
              />
            </div>
          </SectionContentContainer>
        )}

      </Section>
      <Footer />
    </>
  );
};

export default CurriculumPage;
